import api from '@/api'
import { DataType } from '@/models/dataType'
import { User } from '@/models/user'
import { User as SmartPhrUser } from '@auth0/auth0-spa-js'
import { useAuth0 } from '@auth0/auth0-vue'
import { createStore } from 'vuex'

type State = {
  error: any
  token: string | null
  viewer: User | null
  version: string | null
  lastModified: Date | null
  profiles: User[]
  dataTypes: DataType[]
}
export const TOKEN_KEY = 'US_HMS_TOKEN'
const ORGANIZATION_KEY = 'ORGANIZATION'

export default createStore({
  state: {
    error: null,
    token: localStorage.getItem(TOKEN_KEY) ?? null,
    viewer: null,
    version: null,
    lastModified: null,
    profiles: [],
    dataTypes: [],
  },

  getters: {
    isAuthenticated: (state: State) => !!state.viewer,
    viewer: (state: State) => state.viewer,
    error: (state: State) => state.error,
    dataTypes: (state: State) => state.dataTypes,
  },

  mutations: {
    error (state: State, value: any) {
      state.error = value
    },

    token (state: State, token: string | null) {
      if (token) {
        localStorage.setItem(TOKEN_KEY, token)
      } else {
        localStorage.removeItem(TOKEN_KEY)
      }
      state.token = token
    },

    viewer (state: State, viewer: User) {
      localStorage.setItem(ORGANIZATION_KEY, viewer.organization.id)
      state.viewer = viewer
    },

    version (state: State, { version, lastModified }) {
      state.version = version
      state.lastModified = lastModified
    },

    profiles (state: State, profiles: User[]) {
      state.profiles = profiles
    },

    dataTypes (state: State, dataTypes: DataType[]) {
      state.dataTypes = dataTypes
    },
  },

  actions: {
    async authenticate ({ state, getters, dispatch }: { state: any; getters: any; dispatch: any }) {
      if (getters.isAuthenticated) return
      if (state.token) await dispatch('getProfiles')
    },

    async setToken ({ commit }: { commit: any }) {
      const { getAccessTokenSilently } = useAuth0()
      const token = await getAccessTokenSilently()
      commit('token', token)
    },

    async getProfiles ({ dispatch, commit }: { dispatch: any; commit: any }, user: SmartPhrUser) {
      await dispatch('setToken')
      const profiles = await api.usHms
        .get(`api/v1/viewer/profiles/${user.email}`)
        .then((resp: any) => {
          return (resp.data ?? []).map((it: any) => {
            it.picture = user.picture
            return User.createFromJson(it)
          })
        })
        .catch(() => [])

      commit('profiles', profiles)

      // auth後なので少なくとも1件のプロファイルは存在する
      const selectedOrgId = localStorage.getItem(ORGANIZATION_KEY) ?? null
      await dispatch('setViewer', selectedOrgId || profiles[0].organization.id)
    },

    async setViewer ({ state, commit }: { state: any; commit: any }, id: string) {
      const viewer = state.profiles.find((profile: User) => profile.organization.id === id)
      commit('viewer', viewer ?? state.profiles[0])
    },

    async signOut ({ commit }: { commit: any }) {
      commit('token', null)
      commit('profiles', [])
      const url = `https://${process.env.VUE_APP_SMART_PHR_DOMAIN}/v2/logout`
      const query = `returnTo=${window.location.origin}&client_id=${process.env.VUE_APP_SMART_PHR_CLIENT_ID}`
      window.location.replace(`${url}?${query}`)
    },

    async version ({ state, commit }: { state: any; commit: any }, toPath: (string | Location) & Location) {
      const options: any = { credentials: 'same-origin' }
      if (state.lastModified) options.headers = { 'If-Modified-Since': state.lastModified }

      const response = await fetch(`/version?t=${new Date().toISOString()}`, options)
      const responseText = await response.text()

      if (!state.version) {
        const lastVersion = localStorage.getItem('version')
        localStorage.setItem('version', responseText)

        if (lastVersion && lastVersion !== responseText) window.location = toPath

        commit('version', { version: responseText, lastModified: response.headers.get('last-modified') })
      } else if (response.status === 200) {
        if (responseText && responseText !== state.version) window.location = toPath
      }
    },

    async fetchDataTypes ({ state, commit }: { state: any; commit: any }) {
      if (state.dataTypes.length) return
      const dataTypes: DataType[] = await api.smartPhr
        .get('/v1/customDataTypes')
        .then((resp: any) => (resp.data ?? []).map((it: any) => DataType.createFromJson(it)))
        .catch(() => [])

      commit('dataTypes', dataTypes)
    },
  },

  modules: {},
})
